body {
  background: #0d4224;
}

.bg-light {
  background-color: #fafddc !important;
}

section {
  height: 100vh;
}

.dinamic-height {
  height: 100% !important;
}

.money-separator {
  position: absolute;
  background-image: url("./assets/img/wojak_banner.png");
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  opacity: 0.7;
  width: 100vw;
  height: 50px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: unset;
  font-weight: normal;
  font-style: normal;
  font-size: unset;
  margin: unset;
  line-height: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

.react-tooltip {
  z-index: 100 !important;
}

.btn-wojak {
  width: 230px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #6e9c74;
  color: #fafddc;
  border: none;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}

a:hover {
  color: #212e22;
}

.relative {
  position: relative;
}

.grid {
  display: grid !important;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-start {
  display: flex;
  justify-content: start;
  align-items: center;
}

.justify-end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.align-evenly {
  display: flex;
  align-content: space-evenly;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.no-transform {
  text-transform: none !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}
