.navbar {
  position: fixed;
  width: 100%;
  z-index: 10;
  .navbar-nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
  }

  .nav-item {
    font-size: 25px;
    font-weight: bold;
  }

  .nav-logo {
    height: 60px;
    margin: 5px 10px;
    border-radius: 10px;
  }
}
