.title-container {
  .title {
    color: white;
    font-size: 40px;
  }
  .title2 {
    color: white;
    font-size: 30px;
  }
}

.phase-container {
  background-color: rgba(110, 156, 116, 1);
  min-height: 214px;
  margin: 10px 20px;
  border-radius: 10px;
  padding: 10px 10px;
}

.tuck {
  margin-top: -275px;
}

.about-container {
  .description {
    color: white;
    font-size: 25px;
  }
}

.wojak-phone-img {
  height: 470px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Super large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
}
