.title-container {
  .title {
    color: white;
    font-size: 40px;
  }
}

.contact-container {
  .description {
    color: white;
    font-size: 20px;
  }
}

.wojak-contact-img {
  height: 300px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Super large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
}
