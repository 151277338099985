.title-container {
  .title {
    color: white;
    font-size: 40px;
  }
}

.description-container {
  .description {
    color: white;
    font-size: 25px;
  }
}

.wojak-cash-img {
  width: 80%;
}

.background-container {
  position: relative;

  .background-wojak {
    position: absolute;
    background-image: url("../../assets/img/wojak.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Super large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
}
